import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// import app components
import ProductsGrid from "components/productsGrid"

import useShopify from "shopify"

const CollectionSingle = props => {
  const {
    data: {
      wpCollection: { collection },
    },
  } = props

  const { client } = useShopify()

  const [liveProducts, setLiveProducts] = useState(null)
  !liveProducts &&
    client &&
    client.collection
      .fetchWithProducts(collection?.shopifyId)
      .then(collection => {
        setLiveProducts(collection?.products)
      })

  return (
    <Container>
      <ProductsGrid
        products={collection?.products}
        liveProducts={liveProducts}
      />
    </Container>
  )
}

export default CollectionSingle

const Container = styled.div`
  padding: 40px 0 60px;
`

export const CollectionQuery = graphql`
  query CollectionSingleQuery($id: String!) {
    wpCollection(id: { eq: $id }) {
      title

      slug
      shopifyCollectionId
      # data linked from shopify api
      collection {
        id
        shopifyId
        title
        products {
          ...ProductFragment
          # linked back to the wpProduct node
          wpProduct {
            uri
          }
        }
      }
    }
  }
`
