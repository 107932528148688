import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// import mui components
import { Typography } from "@material-ui/core"

// import app components
import BackgroundImage from "components/backgroundImage"

// import useShopify from "shopify"
import config from "shopify/config"

import formatCurrencyString from "utils/formatCurrencyString"
import * as theme from "theme"

const ProductTile = props => {
  const { product, isHero, liveProduct, ...rest } = props
  const { title, media, variants, wpProduct } = product || {}
  const { uri } = wpProduct || {}
  const images = media?.map?.(mediaItem => mediaItem?.image)

  const availableForSale = !!liveProduct
    ? liveProduct.availableForSale
    : product?.availableForSale

  const defaultVariant = variants?.[0]

  const liveDefaultVariant =
    liveProduct?.variants &&
    liveProduct?.variants.find(
      liveVariant => liveVariant.id === defaultVariant?.shopifyId
    )

  const itemPrice =
    (liveDefaultVariant && {
      ...liveDefaultVariant,
      price: liveDefaultVariant?.price?.amount,
    }) ||
    defaultVariant

  return (
    <Container to={uri} {...rest} isHero={isHero}>
      {availableForSale === false && (
        <Badge>
          <Typography variant="caption">Sold out</Typography>
        </Badge>
      )}

      {itemPrice?.compareAtPrice && availableForSale && (
        <Badge>
          <Typography variant="caption">Sale</Typography>
        </Badge>
      )}

      {images?.[0]?.gatsbyImageData && (
        <ImageContainer isHero={isHero}>
          <BackgroundImage className="featured" image={images[0]} />
          {images?.[1]?.gatsbyImageData && (
            <BackgroundImage className="hovered" image={images[1]} />
          )}
        </ImageContainer>
      )}

      <Title>{title}</Title>

      {itemPrice?.price && (
        <Price>
          <Typography
            css={css`
              ${!!itemPrice?.compareAtPrice &&
                css`
                  margin-right: 10px;
                `}
            `}
          >
            {config?.defaultCurrencyCode}{" "}
            {itemPrice?.price &&
              formatCurrencyString({
                amount: itemPrice?.price,
                currencyCode: config?.defaultCurrencyCode,
              })}
          </Typography>

          {!!itemPrice?.compareAtPrice && (
            <Typography
              css={css`
                font-style: italic;
                text-decoration: line-through;
              `}
            >
              {config?.defaultCurrencyCode}{" "}
              {itemPrice?.compareAtPrice?.amount &&
                formatCurrencyString({
                  amount: itemPrice?.compareAtPrice?.amount,
                  currencyCode: itemPrice?.compareAtPrice?.currencyCode,
                })}
            </Typography>
          )}
        </Price>
      )}
    </Container>
  )
}

export default ProductTile

const Container = styled(Link)`
  text-decoration: none;
  position: relative;
  margin-bottom: 30px;
  color: inherit;
  display: block;
  width: 100%;

  @media (min-width: 800px) {
    width: ${({ isHero }) => (isHero ? "100%" : "calc(50% - 20px)")};
  }

  ${({ dummy }) =>
    dummy &&
    css`
      height: 0;
      margin: 0;
    `}

  &:hover {
    text-decoration: none;
  }
`

const Title = styled(Typography)`
  && {
    font-weight: 500;
  }
`

const Badge = styled.div`
  background: ${theme.colors.primary};
  color: #fff;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
  padding: 5px 10px;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  margin-bottom: 5px;

  @media (min-width: 800px) {
    height: ${({ isHero }) => (isHero ? "1200px" : "600px")};
  }

  @media (min-width: 1024px) {
    height: ${({ isHero }) => (isHero ? "1600px" : "800px")};
  }

  .hovered {
    transition: ease all 0.4s;
    opacity: 0;
  }

  &:hover {
    .featured {
      opacity: 1;
    }

    .hovered {
      opacity: 1;
    }
  }
`

const Price = styled.div`
  display: flex;
`
