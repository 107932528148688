import React from "react"
import styled from "styled-components"

import Edges from "components/edges"
import ProductTile from "./ProductTile"

const ProductsGrid = props => {
  const { products, liveProducts, ...rest } = props

  return (
    <Container {...rest}>
      <Edges size="lg">
        {products && (
          <Items>
            {products.map((product, i) => {
              const liveProduct =
                liveProducts &&
                liveProducts.find(
                  liveProduct => liveProduct.id === product.shopifyId
                )

              // const isHero = (i + 1) % 3 === 0

              return (
                <ProductTile
                  key={product.id}
                  product={product}
                  liveProduct={liveProduct}
                  isHero={false}
                />
              )
            })}
            <ProductTile dummy />
            <ProductTile dummy />
            <ProductTile dummy />
            <ProductTile dummy />
          </Items>
        )}
      </Edges>
    </Container>
  )
}

export default ProductsGrid

const Container = styled.div``

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`
